import React from "react";

export const Gallery = ({ title, description, images }) => {
  const galleryImageFirst = images.map((image, i) => {
    if (i === 0) {
      return <img key={i} src={image.image.src} alt={image.image.alt} />;
    }
  });
  const galleryImagesOffset = images.map((image, i) => {
    if (i > 0) {
      return (
        <div key={i} className="w-full md:w-6/12 pb-4 pl-4">
          <img src={image.image.src} alt={image.image.alt} />
        </div>
      );
    }
  });
  const galleryImages = images.map((image) => {
    return (
      <div key={image.image.id} className="w-6/12 pb-4 pl-4">
        <img src={image.image.src} alt={image.image.alt} />
      </div>
    );
  });
  return (
    <>
      <div className="container mx-auto px-3">
        <div className="py-10 text-center px-10 md:px-0">
          {title && <h2 className="text-dulux text-3xl">{title}</h2>}
          {description && (
            <p className={"max-w-lg mx-auto " + (title && "mt-6")}>
              {description}
            </p>
          )}
        </div>

        <div className="md:flex flex-wrap -ml-4 hidden">
          <div
            className={
              "pb-4 pl-4 " + (images.length > 1 ? "w-6/12" : "w-6/12 mx-auto")
            }
          >
            {galleryImageFirst}
          </div>
          {images.length > 1 && (
            <div className="w-6/12 flex flex-wrap">{galleryImagesOffset}</div>
          )}
        </div>
        <div className="flex flex-wrap md:hidden -ml-4 justify-center">
          {galleryImages}
        </div>
      </div>
    </>
  );
};

export default Gallery;
