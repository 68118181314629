import React from "react";
import Gallery from "../ui/Gallery";
import {getFieldAttr} from "../../lib/contentful/utilities/CtfModule";
import { mapImages } from "../../lib/contentful/utilities/ObjectMappers";

const moduleGallery = ({ data }) => {
  const mappedImages = mapImages(getFieldAttr(data, "images"));

  return (
    <Gallery
      title={getFieldAttr(data, "title")}
      description={getFieldAttr(data, "content")}
      images={mappedImages}
    />
  );
};

export default moduleGallery;
