import React from "react";
import { getFieldAttr } from "../../lib/contentful/utilities/CtfModule";
import Feature from "../ui/Feature";

const moduleFeature = ({ data }) => {
  const cta = getFieldAttr(data, "cta");
  const image = getFieldAttr(data, "image");

  return (
    <Feature
      title={getFieldAttr(data, "title")}
      secondaryTitle={getFieldAttr(data, "secondaryTitle")}
      description={getFieldAttr(data, "content")}
      textColour={getFieldAttr(data, "textColour")}
      backgroundColour={getFieldAttr(data, "backgroundColour")}
      image={getFieldAttr(image, "file.url")}
      imageAlt={getFieldAttr(image, "title")}
      ctaLink={getFieldAttr(cta, "link")}
      ctaLabel={getFieldAttr(cta, "label")}
      ctaNewTab={getFieldAttr(cta, "newTab")}
      layout={getFieldAttr(data, "layout", "left")}
    />
  );
};

export default moduleFeature;
