import React, { useEffect } from "react";
import Siema from "siema";
import Cta from "../ui/Cta";
import { ChevronLeft, ChevronRight } from "react-feather";

export const HeroCarousel = ({
  id,
  title,
  description,
  ctaLink,
  ctaText,
  ctaNewTab,
  images,
}) => {

  useEffect(() => {
    if (images.length) {
      const heroSiema = new Siema({
        selector: `#${getId()}`,
        loop: true,
        duration: 300,
      });
      document
        .querySelector(".siema-previous")
        .addEventListener("click", () => heroSiema.prev());
      document
        .querySelector(".siema-next")
        .addEventListener("click", () => heroSiema.next());

      return () => {
        document
          .querySelector(".siema-previous")
          .removeEventListener("click", () => heroSiema.prev());
        document
          .querySelector(".siema-next")
          .removeEventListener("click", () => heroSiema.next());
      }
    }
  }, []);

  const carouselImages = images.map((image) => {
    return <img key={image.image.id} className="w-full h-full"
                src={image.image.src}
                alt={image.image.alt} />;
  });

  function getId() {
    return `hero-carousel-${id}`;
  }

  return (
    <>
      <div className="container mx-auto px-3 flex flex-wrap mb-6 md:mb-10">
        <div className="w-full md:w-5/12 flex flex-col justify-center pr-0 md:pr-10 order-2 md:order-1 mt-4 md:mt-0">
          <div className="pb-0 md:pt-24">
            {title && <h1 className="text-dulux text-4xl">{title}</h1>}
            {description && <p className="mt-3">{description}</p>}
            {ctaLink && (
              <div className={(title || description) && "mt-3"}>
                <Cta link={ctaLink} text={ctaText} newTab={ctaNewTab} />
              </div>
            )}
          </div>
        </div>
        <div className="w-full md:w-7/12 order-1 md:order-2">
          <div className="relative">
            <div id={getId()}>{carouselImages}</div>
            {images.length && (
              <div className="absolute bottom-0 right-0">
                <button className="siema-previous bg-white h-16 w-16 mr-1 mb-1 hover:bg-gray-300 transition-fade hero-carousel-previous">
                  <ChevronLeft
                    className="inline-flex ml-1"
                    width="25"
                    height="25"
                    strokeWidth="2"
                    stroke="#001E45"
                  />
                </button>
                <button className="siema-next bg-white h-16 w-16 mr-1 mb-1 hover:bg-gray-300 transition-fade hero-carousel-next">
                  <ChevronRight
                    className="inline-flex ml-1"
                    width="25"
                    height="25"
                    strokeWidth="2"
                    stroke="#001E45"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroCarousel;
