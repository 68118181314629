import React from "react";

const Tag = ({ children }) => {
  return (
    <div className="absolute text-xs top-0 right-0 px-3 p-1 uppercase font-bold tracking-widest bg-dulux text-white">
      {children}
    </div>
  );
};

export default Tag;
