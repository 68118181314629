import React from "react";
import {items} from "../../lib/contentful/utilities/CtfModule";
import {mapChildItems} from "../../lib/contentful/utilities/ObjectMappers";
import TabGroup from "../ui/TabGroup";

const moduleTabs = ({ data }) => {
  const tabs = items(data);

  const mappedItems = {
    tabs: mapChildItems(tabs)
  };

  return (
    <TabGroup
      data={mappedItems}
      initial={0}
      parseBodyHtml={false}
    />
  );
};

export default moduleTabs;
