import React from "react";
import TileGrid from "../ui/TileGrid";
import { items } from "../../lib/contentful/utilities/CtfModule";
import { mapFilterItems } from "../../lib/contentful/utilities/ObjectMappers";
import { getFieldAttr } from "../../lib/contentful/utilities/CtfModule";

const moduleTileGrid = ({ data }) => {
  const tiles = items(data);
  const mappedTiles = {
    tiles: mapFilterItems(tiles, "moduleTile"),
  };
  return (
    <>
      <TileGrid title={getFieldAttr(data, "title")} data={mappedTiles} />
    </>
  );
};

export default moduleTileGrid;
