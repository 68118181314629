import React from "react";
import {items, getFieldAttr} from "../../lib/contentful/utilities/CtfModule";
import {mapChildItems} from "../../lib/contentful/utilities/ObjectMappers";
import Accordion from "../ui/Accordion";

const moduleAccordion = ({ data }) => {
  const tabs = items(data);

  const mappedItems = {
    title: getFieldAttr(data, "title"),
    tabs: mapChildItems(tabs)
  };

  return (
    <Accordion
      data={mappedItems}
      initial={0}
      parseBodyHtml={false}
    />
  );
};

export default moduleAccordion;
