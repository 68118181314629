import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { contrastingColour } from "../../lib/utilities/colour";
import { motion } from "framer-motion";

export const Hero = props => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const openLinkInNewTab = () => {
    if (props.ctaNewTab) {
      return "_blank";
    }
  };
  return (
    <>
      <div
        className=""
        style={{
          color: contrastingColour(props.backgroundColour),
          backgroundColor: props.backgroundColour,
        }}
      >
        <div className="mb-10 lg:mb-24 xl:mb-56 container mx-auto flex flex-col justify-end h-full py-4 lg:py-16 pt-10 px-3 ">
          <div className="flex flex-col lg:flex-row">
            <motion.div
              initial={{
                opacity: isPageLoaded ? 1 : 0,
              }}
              animate={{
                opacity: isPageLoaded ? 1 : 0,
              }}
              transition={{ duration: 1.5, delay: 0.4 }}
              className="flex flex-col w-full lg:w-2/4 overflow-visible"
            >
              {props.intro && (
                <div
                  dangerouslySetInnerHTML={{ __html: props.intro }}
                  className="w-full lg:w-10/12 text-lg md:text-2xl pb-8 lg:pb-12"
                />
              )}
              {props.introSubText && (
                <div
                  dangerouslySetInnerHTML={{ __html: props.introSubText }}
                  className="w-full lg:w-10/12 text-lg md:text-2xl pb-8 lg:pb-12"
                />
              )}
              {props.imageOne && (
                <div className="max-h-160 hidden lg:block">
                  {props.ctaLink ? (
                    <a href={props.ctaLink} target={openLinkInNewTab()}>
                      <img src={props.imageOne} alt={props.imageOneAlt} />
                    </a>
                  ) : (
                    <img src={props.imageOne} alt={props.imageOneAlt} />
                  )}
                </div>
              )}
            </motion.div>
            <motion.div
              initial={{
                opacity: isPageLoaded ? 1 : 0,
              }}
              animate={{
                opacity: isPageLoaded ? 1 : 0,
              }}
              transition={{ duration: 1.5, delay: 0.8 }}
              className="flex flex-col w-full lg:w-2/4 pl-0 lg:pl-32"
            >
              {props.imageTwo && (
                <div>
                  <div className="max-h-160">
                    {props.ctaLink ? (
                      <a href={props.ctaLink} target={openLinkInNewTab()}>
                        <img src={props.imageTwo} alt={props.imageTwoAlt} />
                      </a>
                    ) : (
                      <img src={props.imageTwo} alt={props.imageTwoAlt} />
                    )}
                  </div>
                </div>
              )}
              <div className="pt-8 lg:pt-24">
                {props.label && (
                  <p className="text-base tracking-widest lg:text-lg uppercase mb-8">
                    {props.label}
                  </p>
                )}
                {props.title && (
                  <>
                    {props.ctaLink ? (
                      <a href={props.ctaLink} target={openLinkInNewTab()}>
                        <h1
                          className="leading-tight mb-2"
                          style={{ fontSize: "3rem" }}
                        >
                          {props.title}
                        </h1>
                      </a>
                    ) : (
                      <h1
                        className="leading-tight mb-2"
                        style={{ fontSize: "3rem" }}
                      >
                        {props.title}
                      </h1>
                    )}
                  </>
                )}
                {props.description && (
                  <h4 className={"text-xl"}>{props.description}</h4>
                )}
                {props.ctaLink && (
                  <a
                    href={props.ctaLink}
                    target={openLinkInNewTab()}
                    className="mt-6 block"
                  >
                    <svg
                      width="76"
                      height="76"
                      viewBox="0 0 76 76"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M64.686 39.0032L9.37856 39.0032V37.4832L64.686 37.4832V39.0032Z"
                        fill={contrastingColour(props.backgroundColour)}
                      />
                      <path
                        d="M59.8531 33.0599C60.0074 33.0599 60.1618 33.1074 60.2984 33.2054L65.1404 36.7145C65.6629 37.0915 65.9627 37.6408 65.9627 38.2226C65.9657 38.8015 65.6659 39.3537 65.1464 39.7337L60.2984 43.2814C59.957 43.5308 59.485 43.4566 59.2356 43.1152C58.9892 42.7767 59.0604 42.3017 59.3989 42.0524L64.2468 38.5047C64.3715 38.4156 64.4428 38.3117 64.4398 38.2227C64.4398 38.1336 64.3686 38.0327 64.2439 37.9436L59.4018 34.4345C59.0634 34.1881 58.9862 33.7131 59.2326 33.3747C59.384 33.1699 59.6156 33.06 59.8531 33.06L59.8531 33.0599Z"
                        fill={contrastingColour(props.backgroundColour)}
                      />
                    </svg>
                  </a>
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

Hero.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.string,
  introSubText: PropTypes.string,
  imageOne: PropTypes.string,
  imageOneAlt: PropTypes.string,
  imageTwo: PropTypes.string,
  imageTwoAlt: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaNewTab: PropTypes.bool,
  backgroundColour: PropTypes.string,
};

Hero.defaultProps = {
  label: "",
  title: "",
  description: "",
  intro: "",
  introSubText: "",
  imageOne: "",
  imageOneAlt: "",
  imageTwo: "",
  imageTwoAlt: "",
  ctaLink: "PropTypes.string",
  ctaLabel: "",
  ctaNewTab: false,
  backgroundColour: "#001E45",
};

export default Hero;
