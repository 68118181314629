import React from "react";
import {getFieldAttr, getSysAttr} from "../../lib/contentful/utilities/CtfModule";
import { mapImages } from "../../lib/contentful/utilities/ObjectMappers";
import Carousel from "../ui/Carousel";

const moduleCarousel = ({ data }) => {
  const images = mapImages(getFieldAttr(data, "images"));
  const mappedSlides = {
    title: getFieldAttr(data, "title"),
    slides: images
  };

  return (
    <Carousel
      id={getSysAttr(data, 'id')}
      data={mappedSlides}
      autoplay={true}
    />
  );
};

export default moduleCarousel;
