import React from "react";
import {
  items,
  getFieldAttr,
  getSysAttr,
  getNodeContentType,
  getNodeKey,
} from "./CtfModule";
import { ctfModules } from "../../../components/contentful/index";

export function mapProducts(modules) {
  return modules
    .filter(module => {
      return "product" === getNodeContentType(module);
    })
    .map(product => {
      let item = {
        ctfFields: {
          colourCode: "",
          tag: "",
        },
        priceRange: {
          minVariantPrice: {
            amount: "",
          },
        },
        variants: {
          edges: [
            {
              node: {
                sku: "",
              },
            },
          ],
        },
      };

      item.id = getFieldAttr(product, "shopifyProduct.id");
      item.title = getFieldAttr(product, "shopifyProduct.title");
      item.collection = getFieldAttr(product, "shopifyProduct.collections", []);
      item.productType = getFieldAttr(product, "shopifyProduct.productType");
      item.priceRange.minVariantPrice.amount = getFieldAttr(
        product,
        "shopifyProduct.priceRange.minVariantPrice.amount"
      );
      item.priceRange.minVariantPrice.currencyCode = getFieldAttr(
        product,
        "shopifyProduct.priceRange.minVariantPrice.currencyCode"
      );
      item.handle = getFieldAttr(product, "shopifyProduct.handle");
      item.images = getFieldAttr(product, "shopifyProduct.images");
      item.variants.edges[0].node = getFieldAttr(
        product,
        "shopifyProduct.variants.edges[0].node"
      );
      item.variants.edges[0].node.sku = getFieldAttr(
        product,
        "shopifyProduct.variants.edges[0].node.sku"
      );
      item.ctfFields.colourCode = getFieldAttr(product, "colourCode", "");
      item.ctfFields.tag = getFieldAttr(product, "tag", false);
      item.ctfId = getSysAttr(product, "id");

      return item;
    });
}

export function mapImages(images) {
  return images.map(image => {
    let item = { image: {} };
    item.image.id = getSysAttr(image, "id");
    item.image.src = getFieldAttr(image, "file.url");
    item.image.title = getFieldAttr(image, "title");
    item.image.alt =
      getFieldAttr(image, "title") + " - " + getFieldAttr(image, "description");

    return item;
  });
}

export function mapChildItems(modules) {
  return modules
    .filter(module => {
      return "moduleChildItem" === getNodeContentType(module);
    })
    .map(tab => {
      let item = {};
      let content = items(tab);

      const RawModule = ctfModules["moduleRaw"];

      const bodyHtml = content.map((element, i) => {
        return (
          <RawModule key={getNodeKey(element, `tab-el-${i}`)} data={element} />
        );
      });

      item.id = getSysAttr(tab, "id");
      item.slug = getFieldAttr(tab, "slug");
      item.heading = getFieldAttr(tab, "title");
      item.content = bodyHtml;

      return item;
    });
}

export function mapFilterItems(modules, filter) {
  return modules.filter(module => {
    return filter === getNodeContentType(module);
  });
}
