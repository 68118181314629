import React from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { MARKS } from '@contentful/rich-text-types';
import ReactHtmlParser from "react-html-parser";

const ModuleRaw = ({data}) => {

  // Format for documentToHtmlString
  // See https://www.npmjs.com/package/@contentful/rich-text-html-renderer
  // top level nodeType: "document" required
  // content needs to be an array
  const documentOptions = {
    nodeType: "document",
    content: [data],
  };

  const options = {
      renderMark: {
        [MARKS.BOLD]: text => `<strong>${text}<strong>`,
      },
  };

  function getHtml() {
    return documentToHtmlString(documentOptions, options);
  }

  // This method avoids having to use dangerouslySetInnerHtml
  // around a wrapping element, which is unnecessary and annoying
  return ReactHtmlParser(getHtml());
};

export default ModuleRaw;
