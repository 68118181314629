import React from "react";
import Link from "next/link";
import Cta from "../ui/Cta";

export const FeatureBox = ({
  image,
  imageAlt,
  textColour,
  backgroundColour,
  title,
  description,
  ctaText,
  ctaLink,
  ctaNewTab,
  layout,
}) => {
  const newTab = () => {
    if (ctaNewTab) {
      return "_blank";
    }
  };
  return (
    <>
      <div className="container mx-auto px-3 mt-10">
        <div
          className={
            "relative " +
            (layout === "right" ? "mr-0 md:mr-32" : "ml-0 md:ml-32")
          }
        >
          {image && <img src={image} alt={imageAlt} />}
          {(title || description || ctaLink) && (
            <div
              className={
                "md:absolute md:top-50 md:-translate-y-50 p-8 w-full md:w-8/12 lg:w-6/12 " +
                (layout === "right" ? "right-0 md:-mr-32" : "left-0 md:-ml-32")
              }
              style={{
                color: textColour,
                backgroundColor: backgroundColour,
              }}
            >
              {title && <h2 className="text-2xl font-bold">{title}</h2>}
              {description && (
                <p className={"text-sm " + (title && "mt-3")}>{description}</p>
              )}
              {ctaLink && (
                <div className={(title || description) && "mt-3"}>
                  <Cta
                    link={ctaLink}
                    text={ctaText}
                    color={textColour}
                    newTab={ctaNewTab}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

FeatureBox.defaultProps = {
  backgroundColour: "#001E45",
  textColour: "#FFFFFF",
};

export default FeatureBox;
