import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Tab from "./Tab";
import get from "lodash/get";

const TabGroup = ({initial, data, parseBodyHtml}) => {
  const [selected, setSelected] = useState(handleInitial());

  const tabs = data.tabs.map((tab, i) => {
    return (
      <Tab
        key={`${i}-tab-item-${tab.heading}`}
        index={i}
        label={tab.heading}
        selected={selected}
        setSelected={setSelected}
      />
    );
  });

  function getContent() {
    return get(data, `tabs.[${selected}].content`, "");
  }

  function handleInitial() {
    if ("string" === typeof initial || initial instanceof String) {
      return parseInt(initial);
    }

    return initial;
  }

  return (
    <div className="container mx-auto px-3">
      <div className="max-w-2xl mx-auto py-8 md:py-16">
        <ul className="flex align-middle justify-center border-b-2 border-dulux">
          {tabs}
        </ul>
        <div className="p-4 text-xs leading-loose">
          {parseBodyHtml ? ReactHtmlParser(getContent()) : getContent()}
        </div>
      </div>
    </div>
  );
};

TabGroup.defaultProps = {
  data: [],
  parseBodyHtml: true,
};

export default TabGroup;
