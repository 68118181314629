import React from "react";
import { getFieldAttr } from "../../lib/contentful/utilities/CtfModule";
import FeatureBox from "../ui/FeatureBox";

const moduleFeatureBox = ({ data }) => {
  const cta = getFieldAttr(data, "cta");
  const image = getFieldAttr(data, "image");
  const defaultTextColour = "#FFFFFF";
  const defaultBgColour = "#001E45";

  return (
    <FeatureBox
      title={getFieldAttr(data, "title")}
      description={getFieldAttr(data, "content")}
      textColour={getFieldAttr(data, "textColour", defaultTextColour)}
      backgroundColour={getFieldAttr(data, "backgroundColour", defaultBgColour)}
      image={getFieldAttr(image, "file.url")}
      imageAlt={getFieldAttr(image, "title")}
      ctaText={getFieldAttr(cta, "label")}
      ctaLink={getFieldAttr(cta, "link")}
      ctaNewTab={getFieldAttr(cta, "newTab")}
      layout={getFieldAttr(data, "layout", "left")}
    />
  );
};

export default moduleFeatureBox;
