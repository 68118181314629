import React from "react";
import PropTypes from "prop-types";
import Tile from "./Tile";

const TileGrid = props => {
  const tiles = props.data.tiles.map((tile, i) => {
    return (
      <Tile
        key={i}
        title={tile.data.target.fields.title}
        secondaryTitle={tile.data.target.fields.secondaryTitle}
        textBackground={tile.data.target.fields.textBackground}
        titleCentered={tile.data.target.fields.titleCentered}
        label={tile.data.target.fields.label}
        image={tile.data.target.fields.image.fields.file.url}
        ctaLink={tile.data.target.fields.link}
        ctaNewTab={tile.data.target.fields.linkNewTab}
        layout={tile.data.target.fields.textLayout}
      />
    );
  });
  return (
    <div className="container mx-auto px-3 pb-16 lg:pb-24 xl:pb-32">
      {props.title && <h3 className="text-2xl mb-2">{props.title}</h3>}
      <div className="flex flex-wrap justify-center -mr-3 lg:-mr-6 -mb-3 lg:-mb-6">
        {tiles}
      </div>
    </div>
  );
};

TileGrid.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};

TileGrid.defaultProps = {
  title: "",
  data: [],
};

export default TileGrid;
