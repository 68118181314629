import React from "react";
import Cta from "../ui/Cta";

export const Hero = ({
  backgroundImage,
  textColour,
  backgroundColour,
  height,
  title,
  description,
  ctaText,
  ctaLink,
  ctaNewTab,
}) => {
  const bgImage = () => {
    return backgroundImage ? `url(${backgroundImage})` : "";
  };

  const newTab = () => {
    if (ctaNewTab) {
      return "_blank";
    }
  };

  const heightClass = () => {
    switch (height) {
      case "small":
        return "hero-box-small ";
      case "large":
        return "hero-box-large ";
      default:
        return "hero-box-small ";
    }
  };

  return (
    <>
      <div
        className={"bg-cover bg-center " + heightClass()}
        style={{
          backgroundImage: bgImage(),
          color: textColour,
          backgroundColor: backgroundColour,
        }}
      >
        <div className="container mx-auto flex flex-col justify-end h-full md:px-3">
          <div className="flex justify-end">
            {(title || description || ctaLink) && (
              <div
                className="w-full lg:w-6/12 py-8 mb-0 md:-mb-6 px-10 hidden md:block"
                style={{
                  color: textColour,
                  backgroundColor: backgroundColour,
                }}
              >
                {title && <h1 className="text-2xl font-bold">{title}</h1>}
                {description && (
                  <p className={"text-sm " + (title && "mt-4")}>
                    {description}
                  </p>
                )}
                {ctaLink && (
                  <div className={(title || description) && "mt-4"}>
                    <Cta
                      link={ctaLink}
                      text={ctaText}
                      color={textColour}
                      newTab={ctaNewTab}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="w-full py-4 px-6 md:hidden"
        style={{
          color: textColour,
          backgroundColor: backgroundColour,
        }}
      >
        {title && <h1 className="text-2xl font-bold">{title}</h1>}
        {description && (
          <p className={"text-sm " + (title && "mt-4")}>{description}</p>
        )}
        {ctaLink && (
          <div className={(title || description) && "mt-4"}>
            <Cta
              link={ctaLink}
              text={ctaText}
              color={textColour}
              newTab={ctaNewTab}
            />
          </div>
        )}
      </div>
    </>
  );
};

Hero.defaultProps = {
  ctaNewTab: false,
  backgroundColour: "#001E45",
  textColour: "#FFFFFF",
};

export default Hero;
