import React from "react";
import { ArrowRight } from "react-feather";

export const Cta = ({ link, text, newTab, color, arrow }) => {
  const openInNewTab = () => {
    if (newTab) {
      return "_blank";
    }
  };
  return (
    <>
      <a
        href={link}
        className="font-semibold inline-flex items-center font-bold hover:underline"
        target={openInNewTab()}
        style={{
          color: color,
        }}
      >
        {text ? text : link}
        {arrow && (
          <ArrowRight
            className="inline-block ml-1"
            width="20"
            height="20"
            strokeWidth="2"
            stroke={color}
          />
        )}
      </a>
    </>
  );
};

Cta.defaultProps = {
  arrow: true,
  color: "#001E45",
};

export default Cta;
