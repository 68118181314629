import React from "react";
import { getFieldAttr } from "../../lib/contentful/utilities/CtfModule";
import HeroBox from "../ui/HeroBox";

const moduleHeroBox = ({ data }) => {
  const cta = getFieldAttr(data, "cta");
  const bgImage = getFieldAttr(data, "backgroundImage");
  const defaultTextColour = "#FFFFFF";
  const defaultBgColour = "#001E45";

  return (
    <HeroBox
      title={getFieldAttr(data, "title")}
      description={getFieldAttr(data, "content")}
      textColour={getFieldAttr(data, "textColour", defaultTextColour)}
      backgroundColour={getFieldAttr(data, "backgroundColour", defaultBgColour)}
      backgroundImage={getFieldAttr(bgImage, "file.url")}
      ctaText={getFieldAttr(cta, "label")}
      ctaLink={getFieldAttr(cta, "link")}
      ctaNewTab={getFieldAttr(cta, "newTab")}
    />
  );
};

export default moduleHeroBox;
