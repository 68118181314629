import RawAsset from "./RawAsset";
import ModuleRaw from "./ModuleRaw";
import ModuleCarousel from "./ModuleCarousel";
import ModuleHero from "./ModuleHero";
import ModuleHeroBox from "./ModuleHeroBox";
import ModuleFeature from "./ModuleFeature";
import ModuleFeatureBox from "./ModuleFeatureBox";
import ModuleGallery from "./ModuleGallery";
import ModuleHeroCarousel from "./ModuleHeroCarousel";
import ModuleAccordion from "./ModuleAccordion";
import ModuleProductsGrid from "./ModuleProductsGrid";
import ModuleTabs from "./ModuleTabs";
import ModuleTileGrid from "./ModuleTileGrid";

export const ctfModules = {
  Asset: RawAsset,
  moduleRaw: ModuleRaw,
  moduleAccordion: ModuleAccordion,
  moduleCarousel: ModuleCarousel,
  moduleHero: ModuleHero,
  moduleHeroBox: ModuleHeroBox,
  moduleFeature: ModuleFeature,
  moduleFeatureBox: ModuleFeatureBox,
  moduleGallery: ModuleGallery,
  moduleHeroCarousel: ModuleHeroCarousel,
  moduleProductsGrid: ModuleProductsGrid,
  moduleTabs: ModuleTabs,
  moduleTileGrid: ModuleTileGrid,
};
