import React from "react";
import ProductsGrid from "../ui/ProductsGrid";
import {items, getFieldAttr} from "../../lib/contentful/utilities/CtfModule";
import { mapProducts } from "../../lib/contentful/utilities/ObjectMappers";
import lib from "../../lib";
import {useDispatch} from "react-redux";

const moduleProductsGrid = ({ data }) => {
  const dispatch = useDispatch();
  const contentModules = items(data);
  const productData = mapProducts(contentModules);
  const cta = getFieldAttr(data, "cta");

  return (
    <ProductsGrid
      data={productData}
      title={getFieldAttr(data, "title")}
      addToCartAction={(variantId, quantity) => dispatch(lib.actions.checkout.addLineItem(variantId, quantity))}
      trackProduct={lib.actions.tracking.trackProduct}
      ctaLink={getFieldAttr(cta, "link")}
      ctaText={getFieldAttr(cta, "label", "Explore other colours")}
      ctaNewTab={getFieldAttr(cta, "newTab")}
    />
  );
};

export default moduleProductsGrid;
