import React from "react";

const Tab = ({ index, label, selected, setSelected }) => {
  return (
    <div
      className={
        "inline-block w-48 p-4 text-center cursor-pointer hover:bg-gray-300 transition-fade" +
        " " +
        activeClass()
      }
      onClick={() => select()}
    >
      {label}
    </div>
  );

  function activeClass() {
    return isSelected() && "bg-dulux text-white hover:bg-dulux";
  }

  function isSelected() {
    return selected === index;
  }

  function select() {
    if (!isSelected()) {
      setSelected(index);
    }
  }
};

export default Tab;
