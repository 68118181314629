import React, { useEffect, useState } from "react";
import ProductCard from "../ui/ProductCard";
import Cta from "../ui/Cta";
import get from "lodash/get";
import { motion } from "framer-motion";

const ProductsGrid = ({
  data,
  title,
  addToCartAction,
  ctaLink,
  ctaText,
  ctaNewTab,
  trackProduct,
}) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  let productsDisplay = (
    <div className="mx-3 bg-dulux border-dulux border-2 w-full my-6 flex items-center justify-center py-6 px-4 lg:text-xl text-white">
      I'm sorry, there are no results. Please try changing the filters.
    </div>
  );

  if (data.length) {
    productsDisplay = data.map((product, i) => {
      let hasStickers = false;
      if (typeof product.collections != "undefined") {
        product.collections.edges.forEach(element => {
          if (element.node.title === "Colour Stickers") {
            hasStickers = true;
          }
        });
      }

      return (
        <ProductCard
          key={get(product, "id", `product-${i}-product.handle`)}
          title={product.title}
          collection={product.collections}
          price={get(product, "priceRange.minVariantPrice.amount")}
          slug={product.handle}
          images={product.images}
          colourCode={get(product, "ctfFields.colourCode", "")}
          tag={get(product, "ctfFields.tag")}
          variant={get(product, "variants.edges[0].node")}
          addToCartAction={addToCartAction}
          product={product}
          productPositionIndex={i}
          trackProduct={trackProduct}
          colourSticker={hasStickers}
        />
      );
    });
  }

  return (
    <div className="container mx-auto px-3">
      {(title || ctaLink) && (
        <div
          className={
            "mt-8 flex flex-wrap items-center " +
            (ctaLink ? "justify-between" : "justify-center")
          }
        >
          {title && (
            <div className="w-full sm:w-auto">
              <h1 className="text-dulux text-2xl">{title}</h1>
            </div>
          )}
          {ctaLink && (
            <div className="w-full sm:w-auto mt-3 sm:mt-0">
              <Cta link={ctaLink} text={ctaText} newTab={ctaNewTab} />
            </div>
          )}
        </div>
      )}
      <motion.div
        initial={{
          opacity: isPageLoaded ? 1 : 0,
        }}
        animate={{
          opacity: isPageLoaded ? 1 : 0,
        }}
        transition={{ duration: 1.5, delay: 0.5 }}
        className={
          "flex flex-wrap justify-start mt-8 -mx-3 " +
          (title && !ctaLink ? "justify-center" : "")
        }
      >
        {productsDisplay}
      </motion.div>
    </div>
  );
};

export default ProductsGrid;
