import React from "react";
import { getFieldAttr } from "../../lib/contentful/utilities/CtfModule";
import Hero from "../ui/Hero";

const moduleHero = ({ data }) => {
  const imageOne = getFieldAttr(data, "imageOne");
  const imageTwo = getFieldAttr(data, "imageTwo");
  const cta = getFieldAttr(data, "cta");

  return (
    <Hero
      label={getFieldAttr(data, "label")}
      title={getFieldAttr(data, "title")}
      description={getFieldAttr(data, "description")}
      intro={getFieldAttr(data, "intro")}
      introSubText={getFieldAttr(data, "introSubText")}
      imageOne={getFieldAttr(imageOne, "file.url")}
      imageOneAlt={getFieldAttr(imageOne, "title")}
      imageTwo={getFieldAttr(imageTwo, "file.url")}
      imageTwoAlt={getFieldAttr(imageTwo, "title")}
      ctaLink={getFieldAttr(cta, "link")}
      ctaLabel={getFieldAttr(cta, "label")}
      ctaNewTab={getFieldAttr(cta, "newTab")}
      backgroundColour={getFieldAttr(data, "backgroundColour")}
    />
  );
};

export default moduleHero;
