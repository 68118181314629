import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import AccordionItem from "./AccordionItem";
import {ltrim} from "../../lib/utilities/trim";

const Accordion = ({ data, initial, parseBodyHtml }) => {
  const [selected, setSelected] = useState(handleInitial());
  const [fragment, setFragment] = useState();

  // This is in a useEffect so that it only happens on the client/browser
  useEffect(() => {
    setFragment(ltrim(window.location.hash, '#'));
  }, []);

  function handleInitial() {
    if ("string" === typeof initial || initial instanceof String) {
      return parseInt(initial);
    }

    return initial;
  }

  const items = data.tabs.map((item, i) => {
    return (
      <AccordionItem
        key={item.id}
        index={i}
        heading={item.heading}
        slug={item.slug}
        fragment={fragment}
        selected={selected}
        setSelected={setSelected}
        last={data.length === i + 1}
      >
        {parseBodyHtml ? ReactHtmlParser(item.content) : item.content}
      </AccordionItem>
    );
  });

  function title() {
    return data.title ? (
      <h2 className="block text-center text-2xl pt-8 pb-4">{data.title}</h2>
    ) : (
      ""
    );
  }

  return (
    <div className="container mx-auto px-3">
      <div className="max-w-2xl mx-auto">
        {title()}
        <div>{items}</div>
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  parseBodyHtml: true,
};

export default Accordion;
