import React, { useEffect } from "react";
import Siema from "siema";

const Carousel = ({ id, data, autoplay }) => {
  useEffect(() => {
    if (data.slides.length) {
      const carousel_options = {
        selector: `#${getId()}`,
        loop: true,
        duration: 200,
        perPage: {
          480: 1,
          768: 2,
          993: 3,
          1400: 4,
        },
      };

      const carousel = new Siema(carousel_options);

      if (autoplay) {
        setInterval(() => {
          autoplay ? carousel.next(1) : "";
        }, 3000);
      }
    }
  }, []);

  const slides = data.slides.map((slide, i) => {
    return (
      <div key={slide.image.id} className="px-2">
        <h2 className="text-md">{slide.title}</h2>
        <img src={slide.image.src} alt={slide.image.alt} className="block" />
      </div>
    );
  });

  function getId() {
    return `carousel-${id}`;
  }

  function title() {
    return data.title ? (
      <h2 className="block text-center text-2xl p-4 pb-8 md:pb-12">
        {data.title}
      </h2>
    ) : (
      ""
    );
  }

  return (
    <div className="container mx-auto px-3 pb-4 md:pb-8">
      {title()}
      {data.slides.length ? (
        <div id={getId()} className="-mx-2">{slides}</div>
      ) : <h1>Something else</h1>
      }
    </div>
  );
};

Carousel.defaultProps = {
  autoplay: false,
};

export default Carousel;
