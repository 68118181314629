import React, { useEffect } from "react";
import { Plus, Minus } from "react-feather";
import ScrollableAnchor, { goToAnchor } from "react-scrollable-anchor";

const AccordionItem = ({ heading, children, index, fragment, slug, selected, setSelected, last}) => {
  useEffect(() => {
    if(fragment === slug) {
      setSelected(index);
      goToAnchor(slug);
    }
  }, [fragment]);

  return (
    <ScrollableAnchor id={slug}>
      <div className="mb-4 border-b border-black">
        <div className="py-4 flex justify-between items-center w-full cursor-pointer"
             onClick={() => toggle()}>
          <h2>{heading}</h2>
          { getChevron() }
        </div>
        <div className={getClass()}>
          { children }
        </div>
      </div>
    </ScrollableAnchor>
  );

  function toggle()
  {
    isSelected() ? setSelected('') : setSelected(index);
  }

  function getClass()
  {
    const classes = "block text-sm py-3";

    return classes + " " + (isSelected() ? "block" : "hidden");
  }

  function isSelected()
  {
    return index === selected;
  }

  function getChevron()
  {
    return isSelected() ? <Minus /> : <Plus />;
  }
};

AccordionItem.defaultProps = {
  heading: "",
  content: "",
  fragment: "",
  show: false,
  last: false,
};

export default AccordionItem;
