import React from "react";
import PropTypes from "prop-types";
import Tag from "../ui/Tag";
import get from "lodash/get";
import Link from "next/link";
import routes from "../../lib/routes";
import { productClick } from "../../lib/analytics";

const ProductCard = props => {
  const productRoute = routes.PRODUCT(props.slug);
  
  function getProductTitle() {
    if (!props.colourCode) {
      return props.title;
    }

    return props.title.replace(props.colourCode, "");
  }

  function getProductImage() {
    const firstImage = get(props.images, "edges.0.node", null);

    if (firstImage) {
      return <img src={firstImage.transformedSrc} alt={firstImage.altText} />;
    }
  }

  function handleClick() {
    productClick(props.product);
    props.trackProduct(
      "Product Clicked",
      props.product,
      props.productPositionIndex
    );
  }

  return (
    <div className="min-w-44 w-6/12 sm:w-6/12 lg:w-4/12 xl:w-3/12 px-3 mb-6">
      <div className="relative">
        {props.tag && <Tag>{props.tag}</Tag>}
        <Link {...productRoute}>
          <a title={`View product: ${props.title}`} onClick={handleClick}>
            {getProductImage()}
          </a>
        </Link>

        <Link {...productRoute}>
          <a
            title={`View product: ${props.title}`}
            onClick={handleClick}
            className="block mt-2 text-base md:text-lg text-black flex flex-col md:flex-row items-center justify-center hover:underline"
          >
            {props.title && (
              <span className="text-center">{getProductTitle()}</span>
            )}
            {props.colourCode && (
              <span className="opacity-50 ml-0 md:ml-2 text-center">
                {props.colourCode}
              </span>
            )}
          </a>  
        </Link>
        
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string,
  images: PropTypes.object,
  slug: PropTypes.string,
  colourCode: PropTypes.string,
  colourSticker: PropTypes.bool,
  tag: PropTypes.string,
  product: PropTypes.object,
  productPositionIndex: PropTypes.number,
  trackProduct: PropTypes.func,
  collection: PropTypes.object,
};

ProductCard.defaultProps = {
  title: "",
  images: [],
  slug: "",
  colourCode: "000000",
  colourSticker: false,
  tag: "",
  product: [],
  productPositionIndex: 0,
  trackProduct: null,
  collection: [],
};

export default ProductCard;
