import React from "react";
import PropTypes from "prop-types";
import { contrastingColour } from "../../lib/utilities/colour";
import { nl2br } from "../../lib/contentful/utilities";

const Feature = props => {
  const openLinkInNewTab = () => {
    if (props.ctaNewTab) {
      return "_blank";
    }
  };

  function textColour() {
    if (props.textColour) {
      return props.textColour;
    } else {
      return contrastingColour(props.backgroundColour);
    }
  }

  return (
    <>
      <div
        className={props.image ? "mb-20 lg:mb-40 xl:mb-48" : ""}
        style={{
          backgroundColor: props.backgroundColour,
          color: textColour(),
        }}
      >
        <div className="container mx-auto px-3 flex flex-col md:flex-row">
          {props.image && (
            <div className="flex justify-center items-end lg:-mb-12 lg:pt-12 -mx-3 sm:mx-0 lg:w-6/12">
              {props.ctaLink ? (
                <a href={props.ctaLink} target={openLinkInNewTab()}>
                  <img
                    className="w-full pt-8 md:pt-0"
                    src={props.image}
                    alt={props.imageAlt}
                  />
                </a>
              ) : (
                <img
                  className="w-full pt-8 md:pt-0"
                  src={props.image}
                  alt={props.imageAlt}
                />
              )}
            </div>
          )}
          {(props.title || props.description || props.ctaLink) && (
            <div
              className={
                "pt-4 pb-8 flex flex-col justify-center lg:w-6/12 " +
                (props.layout === "right"
                  ? "md:order-last md:pl-4 md:pr-4 lg:pl-16 lg:pr-24"
                  : "md:order-first md:pr-4 lg:pr-24")
              }
            >
              {props.secondaryTitle && (
                <h4 className="uppercase text-body tracking-widest text-lg mb-8">
                  {props.secondaryTitle}
                </h4>
              )}
              {props.title && (
                <>
                  {props.ctaLink ? (
                    <a href={props.ctaLink} target={openLinkInNewTab()}>
                      <h2 className="text-5xl">{props.title}</h2>
                    </a>
                  ) : (
                    <h2 className="text-5xl">{props.title}</h2>
                  )}
                </>
              )}
              {props.description && (
                <p
                  className={"text-lg " + (props.title && "mt-2")}
                  dangerouslySetInnerHTML={{ __html: nl2br(props.description) }}
                ></p>
              )}
              {props.ctaLink && (
                <a href={props.ctaLink} target={openLinkInNewTab()}>
                  <svg
                    className="w-12 h-12 md:w-16 md:h-16 mt-4"
                    viewBox="0 0 76 76"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M64.686 39.0032L9.37856 39.0032V37.4832L64.686 37.4832V39.0032Z"
                      fill={textColour()}
                    />
                    <path
                      d="M59.8531 33.0599C60.0074 33.0599 60.1618 33.1074 60.2984 33.2054L65.1404 36.7145C65.6629 37.0915 65.9627 37.6408 65.9627 38.2226C65.9657 38.8015 65.6659 39.3537 65.1464 39.7337L60.2984 43.2814C59.957 43.5308 59.485 43.4566 59.2356 43.1152C58.9892 42.7767 59.0604 42.3017 59.3989 42.0524L64.2468 38.5047C64.3715 38.4156 64.4428 38.3117 64.4398 38.2227C64.4398 38.1336 64.3686 38.0327 64.2439 37.9436L59.4018 34.4345C59.0634 34.1881 58.9862 33.7131 59.2326 33.3747C59.384 33.1699 59.6156 33.06 59.8531 33.06L59.8531 33.0599Z"
                      fill={textColour()}
                    />
                  </svg>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Feature.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  textColour: PropTypes.string,
  backgroundColour: PropTypes.string,
  secondaryTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaNewTab: PropTypes.bool,
  layout: PropTypes.string,
};

Feature.defaultProps = {
  image: "",
  imageAlt: "",
  textColour: "",
  backgroundColour: "#001E45",
  secondaryTitle: "",
  title: "",
  description: "",
  ctaLink: "",
  ctaLabel: "",
  ctaNewTab: false,
  layout: "left",
};

export default Feature;
