import React from "react";
import {getFieldAttr} from "../../lib/contentful/utilities/CtfModule";

const RawAsset = ({data}) => {

  function isImage() {
    let contentType = getFieldAttr(data, 'file.contentType');

    // Only allow for images. Any other media type gets ignored
    return contentType.includes('image');
  }

  if(isImage()) {
    return <img src={getFieldAttr(data, 'file.url')}
         alt={getFieldAttr(data, 'title')}
         className="block py-4"/>
  }
};

export default RawAsset;
