import React from "react";
import HeroCarousel from "../ui/HeroCarousel";
import { getFieldAttr, getSysAttr } from "../../lib/contentful/utilities/CtfModule";
import { mapImages } from "../../lib/contentful/utilities/ObjectMappers";

const moduleHeroCarousel = ({ data }) => {
  const mappedImages = mapImages(getFieldAttr(data, "images"));

  return (
    <HeroCarousel
      id={getSysAttr(data, 'id')}
      title={getFieldAttr(data, "title")}
      description={getFieldAttr(data, "content")}
      ctaLink={getFieldAttr(data, "cta.link")}
      ctaText={getFieldAttr(data, "cta.label")}
      ctaNewTab={getFieldAttr(data, "cta.newTab", false)}
      ctaHasArrow={getFieldAttr(data, "cta.hasArrow", true)}
      images={mappedImages}
    />
  );
};

export default moduleHeroCarousel;
